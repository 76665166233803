// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-future-horizons-js": () => import("./../../../src/pages/case-studies/future-horizons.js" /* webpackChunkName: "component---src-pages-case-studies-future-horizons-js" */),
  "component---src-pages-case-studies-go-apps-js": () => import("./../../../src/pages/case-studies/go-apps.js" /* webpackChunkName: "component---src-pages-case-studies-go-apps-js" */),
  "component---src-pages-case-studies-identity-js": () => import("./../../../src/pages/case-studies/identity.js" /* webpackChunkName: "component---src-pages-case-studies-identity-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-maritimepiracy-js": () => import("./../../../src/pages/case-studies/maritimepiracy.js" /* webpackChunkName: "component---src-pages-case-studies-maritimepiracy-js" */),
  "component---src-pages-case-studies-moneyball-js": () => import("./../../../src/pages/case-studies/moneyball.js" /* webpackChunkName: "component---src-pages-case-studies-moneyball-js" */),
  "component---src-pages-case-studies-scientific-financial-js": () => import("./../../../src/pages/case-studies/scientific-financial.js" /* webpackChunkName: "component---src-pages-case-studies-scientific-financial-js" */),
  "component---src-pages-case-studies-twymanghoshal-js": () => import("./../../../src/pages/case-studies/twymanghoshal.js" /* webpackChunkName: "component---src-pages-case-studies-twymanghoshal-js" */),
  "component---src-pages-case-studies-zapoint-js": () => import("./../../../src/pages/case-studies/zapoint.js" /* webpackChunkName: "component---src-pages-case-studies-zapoint-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

